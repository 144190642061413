<template>
  <a-layout-footer class="footer">
    <div class="footer-wrapper">
      <div class="container">
        <h5>{{ footer.title }}</h5>
        <a-row :gutter="20">
          <a-col :xs="24" :sm="8" :md="8" :lg="8" :xl="9">
            <div class="describe"><p  v-html="footer.summary"></p></div>
          </a-col>
          <a-col :xs="24" :sm="8" :md="8" :lg="8" :xl="9" class="col-center">
            <ul class="connect">
              <li>地址：{{ footer.address }}</li>
              <li>电话：{{ footer.phone }}</li>
              <li @click="beian()" style="cursor:pointer">备案号：{{ footer.beian }}</li>
<!--              <li>邮箱：{{ footer.email }}</li>-->
            </ul>
          </a-col>
          <a-col :xs="24" :sm="8" :md="8" :lg="8" :xl="6">
            <div class="qrcode">
              <div class="qrcode-item">
                <h6>货主端APP</h6>
                <!-- <img src="@/assets/img/hz.jpg" alt="货主端APP"> -->
                <img :src="androidPicture" alt="货主端APP">
              </div>
              <div class="qrcode-item">
                <h6>司机端APP</h6>
                <!-- <img src="@/assets/img/sj.jpg" alt="司机端APP"> -->
                <img :src="iosPicture" alt="司机端APP">
              </div>
            </div>
          </a-col>
        </a-row>

      </div>
    </div>
    <div class="footer-copyright">
<!--      {{footer.copyright }} | <a href="#" target="_blank">{{ footer.beian }}</a>-->
    </div>

  </a-layout-footer>
</template>

<script>
import {footer} from "../api/footer";
import { Constants } from "@/common/constants.js"

export default {
  name: "Footer"
  , data() {
    return {
      footer: [],
      androidPicture:'',
      iosPicture:''
    }
  }
  , created() {
    this.getFooter()
  }
  , methods: {
    getFooter() {
      const param={
        id: Constants.id
      }
      footer(param).then(response => {
        this.footer = response.data;
        this.androidPicture=response.data.androidPicture;
        this.iosPicture=response.data.iosPicture
      })
    },
    beian(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  text-align: center;
  background-color: rgba(244, 244, 244, 1);

  & .footer-wrapper {
    padding: 20px;
    background-color: rgba(250, 250, 250, 1);

    & > .container {
      & h5 {font-size: 24px;font-weight: 500;margin-bottom: 10px;text-align: left;}


    }

    & .col-center {
      display: flex;
      justify-items: center;
      align-items: center;
    }

    & .describe {
      & > p {font-size: 12px; color: #999999;margin-bottom: 0;text-indent: 2rem;line-height: 2;text-align: justify}
    }

    & .connect {
      & > li {
        padding: 5px 0;
        text-align: left;
        font-size: 14px;
      }
    }

    & .qrcode {
      & > .qrcode-item {display: inline-block;width: 50%;padding: 5px;

        & > h6 {margin-bottom: 5px;font-size: 16px;font-weight: 500;}

        & > img {
          display: inherit;
          width: 106px;
          height: 106px;
          @media (min-width: 1200px) { width: 122px;
            height: 122px;}
        }
      }
    }
  }

  & .footer-copyright {
    padding: 15px;
  }
}

</style>