<template>
  <Carousel></Carousel>
  <Introduce></Introduce>
  <part-data></part-data>
  <Map></Map>
  <Culture></Culture>
  <!-- <Partner></Partner> -->
</template>


<script>
import Carousel from "@/views/home/Carousel";
import PartData from "@/views/home/PartData";
import Map from "@/views/home/Map";
import Culture from "@/views/home/Culture";
import Partner from "@/views/home/Partner";
import Introduce from "@/views/home/Introduce";

export default {
  name: 'Home',
  components: {
    Introduce,
    Partner,
    Culture,
    Map,
    PartData,
    Carousel
  }
}
</script>

