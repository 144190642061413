<template>
  <div class="number-grow-warp">
    <span ref="numberGrow" :data-time="time" class="number-grow" :data-value="value">0</span>
  </div>
</template>
<script>
export default {
  name: 'NumberGrow',
  props: {
    time: {
      type: Number,
      default: 2
    },
    value: {
      type: Number,
      default:720000
    }
  },
  methods: {
    numberGrow(ele) {
      let that = this
      let step = (that.value * 10) / (that.time * 1000)
      let current = 0
      let start = 0
      let t = setInterval(function () {
        start += step
        if (start > that.value) {
          clearInterval(t)
          start = that.value
          t = null
        }
        if (current === start) {
          return
        }
        current = start
        ele.innerHTML = current.toFixed(0).toString().replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1,')
      }, 10)
    }
  },
  mounted() {
    this.numberGrow(this.$refs.numberGrow)
  }
}
</script>
<style>
.number-grow-warp {
  display: inline-block;
  transform: translateZ(0);
}

.number-grow {
  font-size: 36px;
  color: #888888;
  letter-spacing: 2.67px;
}
</style>