import request from "@/utils/request";
export function navbar(data) {
    return request({
        url: '/archives/portal/queryAllLinks',
        method: 'post',
        data
    })
}
export function partners(data) {
    return request({
        url: '/archives/portal/queryAllLinks',
        method: 'post',
        data
    })
}
export function news(data) {
    return request({
        url: '/archives/portal/selectColumnNewsInfo',
        method: 'post',
        data
    })
}
export function newsDetail(data) {
    return request({
        url: '/archives/portal/selectById',
        method: 'post',
        data
    })
}




export function count(data) {
    return request({
        url: '/archives/portal/selectPortalCount',
        method: 'post',
        data
    })
}
export function swiper(data) {
    return request({
        url: '/archives/portal/queryAllSwiper',
        method: 'post',
        data
    })
}