import { createApp } from 'vue';
import 'ant-design-vue/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/style.scss';
import '@/assets/iconfont/iconfont.css';
import 'animate.css';
import '@/styles/index.scss'
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';

createApp(App).use(store).use(router).use(Antd).mount('#app');


