<template>
  <section class="partner">
    <div class="container">
      <h3>合作伙伴</h3>
      <ul>
        <li v-for="partner in partners">
          <a :href="partner.url" target="_blank"><img :src="partner.picture" :alt="partner.name"></a>
        </li>
      </ul>
    </div>
  </section>

</template>

<script>

import { partners } from "@/api";

export default {
  name: "Partner",
  data() {
    return {
      partners: []
    }
  }
  , created() {
    this.getList()
  }
  , methods: {
    getList() {
      partners(this.listQuery).then(response => {
        this.partners = response.data;
      })
    },
  }
}
</script>

<style scoped lang="scss">
.partner {
  padding: 120px 0;
  //background-color: rgba(250, 250, 250, 1);
  & > .container {
    & > h3 {
      font-size: 38px;
      font-weight: 700;
      margin-bottom: 30px;
      text-align: center;
    }

    & > ul {
      position: relative;
      overflow: hidden;

      & > li {
        float: left;

        @media (min-width: 320px) {
          width: 33.333333%;
        }
        @media (min-width: 414px) {
          width: 120px;
          height: 80px;
        }
        @media (min-width: 1000px) {
          width: 200px;
          height: 130px;
        }
        @media (min-width: 1200px) {
          width: 200px;
          height: 130px;
        }

        padding: 10px;

        & > a {
          display: block;
          width: 100%;
          height: 100%;
          padding: 10px;
          box-shadow: 0 1px 4px 0 #ccc;

          & > img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

}

</style>