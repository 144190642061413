<template>
  <header class="navbar">
    <div class="logo"><img src="@/assets/logo.png" alt=" 赛马物联科技（宁夏）有限公司"></div>
    <a-menu v-model:selectedKeys="selectedKeys" mode="horizontal" :style="{ lineHeight: '65px' }"
            overflowedIndicator="|||" theme="dark">

      <a-menu-item key="/">
        <router-link to="/">首页</router-link>
      </a-menu-item>

      <template v-for="nav in navs">

        <a-menu-item v-if="nav.children.length == 0" :key="nav.url" :index="nav.id">
          <router-link :to="{ path: nav.url, query: { columnId: nav.id } }">{{ nav.name }}</router-link>
        </a-menu-item>

        <a-sub-menu v-if="nav.children.length > 0" :index="nav.id" :key="nav.url">
          <template #title>
            <router-link :to="{ path: nav.url, query: { columnId: nav.id, pid: nav.pid  } }">
              {{ nav.name }}
            </router-link>
          </template>
          <a-menu-item-group v-for="childrens in nav.children">
            <a-menu-item :key="'/case/detail/' + childrens.id">
              <router-link :to="{ name: childrens.url, query: { columnId: childrens.id, pid: childrens.pid } }">
                {{ childrens.name }}
              </router-link>
            </a-menu-item>
          </a-menu-item-group>
        </a-sub-menu>
      </template>

      <a-menu-item key="/about">
        <router-link to="/about">关于我们</router-link>
      </a-menu-item>

      <!-- <a-sub-menu key="/about">
        <template #title>
          <router-link to="/about">关于我们</router-link>
          <a href="javascript:;">关于我们</a>
        </template>
        <a-menu-item-group>
          <a-menu-item key="/about">
            <router-link to="/about">关于我们</router-link>
          </a-menu-item>
        </a-menu-item-group>
      </a-sub-menu> -->
    </a-menu>
    <div class="nav-right">
      <a class="ant-dropdown-link icons-list" @click="handleMenuClick">
        <shopping-cart-outlined/>
      </a>
    </div>
    <div class="navs-right">
<!--      <router-link to="/register" class="colors">注册</router-link>-->
<!--      <a href="" target="_blank">登录</a>-->
      <a href="https://portal.wzcwlw.com/#/login" style="color:#ffffff;" target="view_window">注册登录</a>

      <a-dropdown>
<!--        <a class="ant-dropdown-link"   @click.prevent>注册</a>-->

<!--        <template #overlay>-->
<!--          <a-menu>-->
<!--            <a-menu-item>-->
<!--              <a href="https://carrieruat.wzcwlw.com/#/tranfer" target="view_window">平台管理</a>-->
<!--            </a-menu-item>-->
<!--            <a-menu-item>-->
<!--              <a href="https://owneruat.wzcwlw.com/#/tranfer" target="view_window">货主登录</a>-->
<!--            </a-menu-item>-->
<!--          </a-menu>-->
<!--        </template>-->
      </a-dropdown>
    </div>
    <!-- <a-dropdown class="nav-right" placement="bottomCenter">
      <a class="ant-dropdown-link icons-list" @click.prevent >
        
        <shopping-cart-outlined />
      </a>
      <template #overlay>
        <a-menu @click="handleMenuClick">
          <a-menu-item key="0">
            <a target="" rel="noopener noreferrer" href="javascript:;">
              我的商城
            </a>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown> -->
  </header>
</template>

<script>
import {DownOutlined, ShoppingCartOutlined} from '@ant-design/icons-vue';
import {navbar} from "@/api/navbar";
import {Constants} from "@/common/constants.js"

export default {
  name: "Navbar"
  , components: {
    DownOutlined,
    ShoppingCartOutlined
  }
  , data() {
    return {
      navs: [],
      selectedKeys: ['/'],
      queryParam: {
        companyId: Constants.id
      }
    }
  }
  , watch: {
    //检测路由参数发生改变时，刷新当前页面 调用
    '$route': function () {
      this.selectedKeys = [this.$route.path]
      // console.log("selectedKeys:",this.selectedKeys)
      // sessionStorage.setItem('hisRoute', this.$route.meta.module);
    }
  }
  , created() {
    if (sessionStorage.getItem('hisRoute') && sessionStorage.getItem('hisRoute') != '') {
      // this.selectedKeys = [sessionStorage.getItem('hisRoute')]
    }
    this.getNavbar()
  }
  , methods: {
    getNavbar() {
      this.$store.dispatch("GetNavbar").then((res) => {
        this.navs = res.data
      })
    },
    handleMenuClick() {
      // this.$message.info('暂未开放,敬请期待!');
      // console.log(this.$route)
      this.$message.config({
        top: `50%`,
      })
      this.$message.info('暂未开放,敬请期待!');
    },
  }
}
</script>

<style scoped lang="scss">
.ant-dropdown-link{
  color: #fff;
}
  .nav-right {
    position: absolute;
    top: 20px;
    right: 140px;
  }
  .colors{
    color: red !important;
  }
  .navs-right {
    position: absolute;
    top: 20px;
    right: 40px;
    font-size: 16px;
  }
  .icons-list :deep(.anticon) {
    margin-right: 6px;
    font-size: 30px;
    color: red;
  }
  </style>