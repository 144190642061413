<template>
  <section class="part-data" v-if="show">
    <div class="container">
      <h2>我找车大数据平台</h2>

      <ul class="data">
        <li>
          <div class="box mb60">
            <img src="@/assets/img/home/data-1.png">
            <h5>今日车辆已行驶</h5>
            <div>
              <NumberGrow :value="car.todayCarTransport"/>
              公里
            </div>
          </div>
          <div class="box">
            <img src="@/assets/img/home/data-5.png">
            <h5>今日运输车次</h5>
            <div>
              <NumberGrow :value="car.todayTransportCar"/>
              次
            </div>
          </div>
        </li>
        <li>
          <div class="box mb60">
            <img src="@/assets/img/home/data-2.png">
            <h5>今日加入司机</h5>
            <div>
              <NumberGrow :value="car.todayAddDriver"/>
              人
            </div>
          </div>
          <div class="box">
            <img src="@/assets/img/home/data-6.png">
            <h5>今日在线承运商</h5>
            <div>
              <NumberGrow :value="car.todayOnlineCarrier"/>
              人
            </div>
          </div>
        </li>
        <li>
          <div class="box mb60">
            <img src="@/assets/img/home/data-3.png">
            <h5>累积行驶里程</h5>
            <div>
              <NumberGrow :value="car.sumTransportMileage"/>
              公里
            </div>
          </div>
          <div class="box">
            <img src="@/assets/img/home/data-7.png">
            <h5>累计运输车次</h5>
            <div>
<!--              car.sumTransportCar-->
              <NumberGrow :value="car.sumTransportCar"/>
              次
            </div>
          </div>
        </li>
        <li>
          <div class="box mb60">
            <img src="@/assets/img/home/data-4.png">
            <h5>累积加入司机</h5>
            <div>
              <NumberGrow :value="car.sumAddDriver"/>
              人
            </div>
          </div>
          <div class="box">
            <img src="@/assets/img/home/data-8.png">
            <h5>承运商已加入</h5>
            <div>
              <NumberGrow :value="car.sumAddCarrier"/>
              人
            </div>
          </div>
        </li>
      </ul>

      <h4>{{ currDate.year }}年{{ currDate.month }}月{{ currDate.date }}日 已运行{{ passDate }}天</h4>

    </div>
  </section>
</template>

<script>
import NumberGrow from "@/components/NumberGrow";
import {count, newsDetail} from "@/api";

export default {
  name: "PartData"
  , components: {NumberGrow}
  , data() {
    return {
      car: {},
      currDate: {
        year: '1',
        month: '2',
        date: '3'
      },
      passDate: '',
      show: false
    }
  }
  , mounted() {
    let time = new Date();
    this.currDate.year = time.getFullYear();
    this.currDate.month = time.getMonth() + 1;
    this.currDate.date = time.getDate();
    let now = time.getTime();
    let startTime = new Date('2021-5-1').getTime();
    // let startTime = new Date('2018-10-1').getTime();
    this.passDate = parseInt((now - startTime) / (1000 * 3600 * 24))

    window.addEventListener('scroll', this.onScroll);
    count({}).then(response => {
      this.car = response.data;
    })
  },
  destroy() {
    window.removeEventListener('scroll', this.onScroll)
  }

  , methods: {
    onScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 800) {
        this.show = true;
      }
    }


  }
}
</script>

<style scoped lang="scss">
.part-data {
  padding-top: 40px;
  padding-bottom: 42px;

  & h2 {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px;
  }

  & h4 {
    font-size: 16px;
    font-weight: 400;
    text-align: right;
  }

  & ul.data {
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;

    & > li {
      width: 25%;
      @media (min-width: 320px) {
        width: 50%;
        &:nth-child(2) {
          border-right: none;
        }
      }
      @media (min-width: 995px) {
        &:nth-child(2) {
          border-right: 1px solid rgba(221, 221, 221, 100);
        }
        width: 25%;
      }
      float: left;
      text-align: center;
      border-right: 1px solid rgba(221, 221, 221, 100);

      &:last-child {
        border-right: none;
      }
    }
  }

  & .box {
    @media (min-width: 320px) {
      margin-bottom: 30px !important;
      &.mb60 {
        margin-bottom: 30px !important;
      }
    }

    img {
      display: inline-block;
      width: 60px;
      height: 60px;
    }

    h5 {
      font-size: 18px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}


</style>