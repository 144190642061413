<template>
  <swiper
    :navigation="true"
    :modules="modules"
    :autoplay="{ delay: 2500, disableOnInteraction: false }"
  >
    <swiper-slide v-for="swiper in swipers">
      <img :src="swiper.picture" alt="" v-if="swiper.utype == '2'"/>
      <video
        autoplay
        muted
        loop
        style="width: 100%; height: 100%; object-fit: cover; display: block;"
        v-if="swiper.utype == '1'"
        :src="swiper.picture"
      ></video>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.min.css";
import { swiper } from "../../api";
import { Autoplay, Navigation } from "swiper";
import {Constants} from '../../common/constants';

export default {
  name: "Carousel",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swipers: "",
      modules: [Autoplay, Navigation],
    };
  },
  mounted() {
    swiper({companyId:Constants.id}).then((response) => {
      this.swipers = response.data;
    });
  },
};
</script>

<style scoped lang="scss">
.swiper {
  width: 100%;
  height: 480px;
  //@media (min-width: 320px) { height: 300px;}
  //@media (min-width: 414px) { height: 300px;}
  & .swiper-slide {
    & video {
      width: 100%;
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>