<template>
  <a-config-provider :locale="locale">
    <a-layout class="layout">
          <Navbar/>
      <a-layout-content style="background-color: rgb(229, 236, 252)">
        <router-view/>
      </a-layout-content>
      <Footer/>
    </a-layout>
  </a-config-provider>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

export default {
  components: {Footer, Navbar}
  , data() {
    return {
      locale: zhCN,
    };
  },
}
</script>

<style scoped lang="scss">
#app {
  background-color: rgb(229, 236, 252);
}


</style>