<template>
  <section class="culture">
    <div class="container">
      <ul>
        <li>
          <div class="box">
            <img src="@/assets/img/home/culture-1.png" alt="">
            <h3>让社会放心</h3>
            <div class="tit">
              <p>央企实力、央企品牌</p>
              <p>解决货运物流痛点</p>
              <p>制造业供应链及时性</p>
              <p>保障工业数据安全性</p>
            </div>
<!--            <a href="//180.76.50.115:83" target="_blank">查看更多<i class="iconfont icon-jia"></i></a>-->
          </div>
        </li>
        <li>
          <div class="box">
            <img src="@/assets/img/home/culture-2.png" alt="">
            <h3>让货主安心</h3>
            <div class="tit">
              <p>物流管理透明化</p>
              <p>原燃料溯源</p>
              <p>产品流向受控</p>
              <p>物流成本降低</p>
            </div>
<!--            <a href="//180.76.50.115:83" target="_blank">查看更多<i class="iconfont icon-jia"></i></a>-->
          </div>
        </li>
        <li>
          <div class="box">
            <img src="@/assets/img/home/culture-3.png" alt="">
            <h3>让企业舒心</h3>
            <div class="tit">
              <p>有车匹配货源</p>
              <p>无车提供车源</p>
              <p>网上对账支付</p>
              <p>缺钱提供普惠金融</p>
            </div>
<!--            <a href="//180.76.50.115:83" target="_blank">查看更多<i class="iconfont icon-jia"></i></a>-->
          </div>
        </li>
        <li>
          <div class="box">
            <img src="@/assets/img/home/culture-4.png" alt="">
            <h3>让司机暖心</h3>
            <div class="tit">
              <p>无车提供共享卡车创业</p>
              <p>缩短运单排队时间</p>
              <p>更多时间陪伴家人</p>
              <p>公平、优惠的服务机会</p>
            </div>
<!--            <a href="//180.76.50.115:83" target="_blank">查看更多<i class="iconfont icon-jia"></i></a>-->
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "Culture"
}
</script>

<style scoped lang="scss">
.icon-jia {margin-left: 10px;font-size: 14px;}
.culture {
  background-color: #fafafa;
  & > .container {
    padding: 114px 0;
    & ul {
      position: relative;
      overflow: hidden;
      & > li {
        float: left;
        @media (min-width: 320px) {   width: 50%;}
        @media (min-width: 414px) {   width: 50%;}
        @media (min-width: 1200px) {  width: 25%;}
        text-align: center;
        & >.box {
          @media (min-width: 320px) { margin-bottom: 20px;}
          @media (min-width: 414px) {   margin-bottom: 20px;}
          h3 {
            margin-top: 47px;
            font-weight: 700;
            @media (min-width: 320px) {   font-size: 18px;}
            @media (min-width: 414px) {   font-size: 18px;}
            @media (min-width: 1200px) {   font-size: 28px;}
          }
          .tit {
            padding: 15px 20px;
            font-weight: 500;
            @media (min-width: 320px) {   font-size: 12px;}
            @media (min-width: 414px) {   font-size: 12px;}
            @media (min-width: 1200px) {   font-size: 16px;}
            line-height: 32px;
            margin-top: 36px;
            margin-bottom: 60px;
            & p {text-align: center;}
          }
          a {font-size: 14px;}
        }
      }
    }
  }
}


</style>