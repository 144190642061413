import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            module: '首页'
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/about/About.vue'),
        meta: {
            module: '关于我们'
        }
    },
    {
        path: '/register',
        name: 'register',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/register/index.vue'),
        meta: {
            module: '注册'
        }
    },
    {
        path: '/recruit',
        name: 'Recruit',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/about/Recruit'),
    },
    {
        path: '/case',
        name: 'Case',
        component: () =>
            import ('../views/Case.vue'),
        meta: {
            module: '行业案例'
        }
    },
    {
        path: '/case/detail',
        name: 'CaseDetail',
        component: () =>
            import ('../views/Case.vue'),
    },
    {
        path: '/article',
        name: 'Article',
        component: () =>
            import ('../views/Article.vue')
    },
    {
        path: '/news',
        name: 'News',
        component: () =>
            import ('../views/News.vue'),
        meta: {
            module: '新闻资讯'
        }
    },
    {
        path: '/info',
        name: 'info',
        component: () =>
            import ('../views/info.vue'),
        meta: {
            module: '信息公示'
        }
    },
    {
        path: '/goods',
        name: 'Goods',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Goods.vue'),
        meta: {
            module: '货源信息'
        }
    },
    {
        path: '/service',
        name: 'Service',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Service.vue'),
        meta: {
            module: '增值服务'
        }
    },
    {
        path: '/scheme',
        name: 'Scheme',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Scheme.vue'),
        meta: {
            module: '产品服务'
        }
    }
]

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
     history: createWebHashHistory(),
    routes
})

export default router