<template>

  <section class="introduce">
    <div class="container">
      <a-row :gutter="{ xs: 8, sm: 16, md: 24, lg: 32 }">
        <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">

          <div class="text">

            <div class="tit wow animate__bounceInLeft">
              <h1><i>我找车平台</i></h1>
              <h6>Platform introduction</h6>
            </div>

            <div class="txt wow animate__bounceInRight">
              <p>“我找车”致力于打造一流的智慧物流平台，搭建大众创业、绿碳创新平台，构建阳光、开放、共生、和谐共赢的“工业互联网+网络货运”物流生态圈。</p>
              <p>“我找车”智慧物流平台秉持阳光、公正、透明的原则，实现企业、货主、物流公司、经纪人、信息部、车主、司机和收货
                人信息互联互通，各个角色在平台和谐共生、共享创业，降低自身业务管控风险，提高盈利能力，赢取更多商业合作。</p>
            </div>
          </div>

        </a-col>
        <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <div class="picture">
            <div>
              <img src="@/assets/img/home/1.png" alt="">
              <h4>
                <i>我找车直通车</i>
                <a href="http://180.76.50.115:83/login.html" target="_blank"><i class="iconfont icon--you"></i></a>
              </h4>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </section>

</template>

<script>
import {WOW} from 'wowjs';

export default {
  name: "Introduce",
  data() {
    return {
      show: true
    }
  }
  , mounted() {
    this.$nextTick(() => {
      const wow = new WOW({
        boxClass: 'wow',///动画元件css类（默认为wow）
        animateClass: 'animate__animated',//动画css类（默认为animated）
        offset: 300,//到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true//对异步加载的内容进行操作（默认为true）
      })
      wow.init();
    })


  }
}
</script>

<style scoped lang="scss">
.icon--you { margin-left: 15px; font-size: 18px;}
.introduce {
  @media (min-width: 320px) { padding: 75px 0 60px;}
  @media (min-width: 414px) { padding: 75px 0 60px;}
  @media (min-width: 1200px) { padding: 150px 0 120px;}
  & > .container {
    & .text {
      & h1, h6 { font-weight: 700; }
      & h1 { font-size: 36px; }
      & h6 { font-size: 12px; }
      & p { color: #131212;font-weight: 500;line-height: 2; text-indent: 2rem;
        @media (min-width: 320px) { font-size: 12px;}
        @media (min-width: 414px) { font-size: 12px;}
        @media (min-width: 1200px) { font-size: 16px;}
      }
      & .tit { margin-bottom: 20px; }
    }
    & .picture {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      & img {
        display: block;
        width: 100%;
        height: 100%;
      }
      & h4 {
        font-size: 28px;
        font-weight: 700;
        text-align: right;
        margin-top: 15px;
        &::after {
          position: absolute;
          content: '';
          width: 150px;
          height: 2px;
          transform: translateX(-50%);
          background-color: #131212;
          @media (min-width: 320px) { bottom: -3px; right: -70px;}
          @media (min-width: 414px) { bottom: 0; right: -70px;}
          @media (min-width: 1200px) {bottom: 0;right: -60px}
        }
      }
    }
  }
}

</style>