import { createStore } from 'vuex'
import { navbar } from "@/api/navbar";
import { Constants } from "@/common/constants.js"

export default createStore({
    state: {
        // 导航栏 
        navbar: []
    },
    mutations: {
        SET_NAVBAR: (state, roles) => {
            state.roles = roles
        }
    },
    actions: {
        // 获取用户信息
        GetNavbar({ commit, state }) {
            return new Promise((resolve, reject) => {
                const param = { companyId: Constants.id }
                navbar(param).then(res => {
                    console.log("actions getNavbar :", res)
                    commit('SET_NAVBAR', res.data)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    modules: {

    }
})